import React, { useMemo, useRef, useContext } from "react";
import "normalize.css";
import styled from "styled-components";
import { Range, Theme } from "src/common/types";
import { createArray, getRandom } from "src/common/utils";
import useDebounce from "src/hooks/useDebounce";
import useElementSize, { ISize } from "src/hooks/useElementSize";
import { CSSVariables } from "src/common/constants";
import { ThemeContext } from "../Styles/GlobalStyle";

const BASE_WIDTH = 1920;
const BASE_HEIGHT = 1080;
const BASE_PARTICLE_DENSITY = 65;
const SPEED = 1.1;
const SPREAD = 1;
const MOVEMENT_SPEED_FACTORS: readonly number[] = Object.freeze([0, 50, 100, 300, 600, 900, 1200]);
const PARTICLE_SIZE: readonly [number, number] = Object.freeze([1, 2]);
const LIGHT_RANGE: IHSLRange = Object.freeze<IHSLRange>({
    h: [180, 200],
    s: [40, 70],
    l: [60, 90]
});
const DARK_RANGE: IHSLRange = Object.freeze<IHSLRange>({
    h: [190, 210],
    s: [40, 70],
    l: [60, 90]
});

interface IPosition {
    x: number;
    y: number;
}

interface IParticle {
    position: IPosition;
    toPosition: IPosition;
    color: string;
    particleSize: string;
}

interface IStyledBackground {
    animationSteps: string;
    show: boolean;
    duration: string;
}

interface IHSLRange {
    h: Range;
    s: Range;
    l: Range;
}

function createHSLString(
    hFrom: number,
    hTo: number,
    sFrom: number,
    sTo: number,
    lFrom: number,
    lTo: number
): string {
    return `hsl(${Math.floor(getRandom(hFrom, hTo))}, ${getRandom(sFrom, sTo)}%, ${getRandom(
        lFrom,
        lTo
    )}%)`;
}

function createParticlePair(
    theme: Theme,
    maxWidth: number,
    maxHeight: number,
    scalingFactor: number
): [IParticle, IParticle] {
    const hslRange: IHSLRange = theme === Theme.Light ? LIGHT_RANGE : DARK_RANGE;
    const fromPosition: IPosition = {
        x: Math.floor(Math.random() * maxWidth),
        y: Math.floor(Math.random() * maxHeight)
    };
    const movementSpeedFactor =
        (MOVEMENT_SPEED_FACTORS[Math.round(getRandom(0, MOVEMENT_SPEED_FACTORS.length - 1))] || 0) *
        scalingFactor;

    const toPosition: IPosition = {
        x: fromPosition.x,
        y: fromPosition.y - maxHeight - movementSpeedFactor
    };
    const color = createHSLString(
        hslRange.h[0],
        hslRange.h[1],
        hslRange.l[0],
        hslRange.l[1],
        hslRange.s[0],
        hslRange.s[1]
    );
    const particleSize = `${Math.round(getRandom(PARTICLE_SIZE[0], PARTICLE_SIZE[1]))}px`;

    return [
        {
            position: fromPosition,
            toPosition,
            color,
            particleSize
        },
        {
            position: {
                x: toPosition.x,
                y: fromPosition.y + maxHeight + movementSpeedFactor
            },
            toPosition: {
                x: fromPosition.x,
                y: fromPosition.y
            },
            color,
            particleSize
        }
    ];
}

function particleToBoxShadowToPosition({ toPosition, particleSize, color }: IParticle): string {
    return `${toPosition.x}px ${toPosition.y}px ${SPREAD}px ${particleSize} ${color}`;
}

function particleToBoxShadowFromPosition({ position, particleSize, color }: IParticle): string {
    return `${position.x}px ${position.y}px ${SPREAD}px ${particleSize} ${color}`;
}

function getElementSizeDebounceDelayMs() {
    return 250;
}

function Background() {
    const { theme } = useContext(ThemeContext);

    const backgroundRef = useRef<HTMLDivElement | null>(null);
    const elementSize = useElementSize(backgroundRef);
    const elementSizeRounded = {
        width: Math.round(elementSize.width),
        height: Math.round(elementSize.height)
    };
    const { width: elementWidth, height: elementHeight } = useDebounce<ISize>(
        elementSize,
        getElementSizeDebounceDelayMs,
        (elementSizeMemoed) => elementSizeMemoed as ISize,
        elementSizeRounded
    );

    const loaded =
        elementWidth === elementSizeRounded.width && elementHeight === elementSizeRounded.height;
    const scalingFactor = (elementWidth / BASE_WIDTH + elementHeight / BASE_HEIGHT) / 2;
    const particleCountFactor = Math.pow(elementWidth / 1500, 0.5);
    const particleCount = Math.round(BASE_PARTICLE_DENSITY * scalingFactor * particleCountFactor);

    const particles = useMemo(
        function () {
            return createArray(particleCount, function () {
                return createParticlePair(theme, elementWidth, elementHeight, scalingFactor);
            }).flatMap(function (f) {
                return f;
            });
        },
        [theme, elementWidth, elementHeight, scalingFactor]
    );

    const animationSteps: string = useMemo(
        function () {
            return `
            0% {
              box-shadow: ${particles.map(particleToBoxShadowFromPosition).join(", ")};
            }
            100% {
              box-shadow: ${particles.map(particleToBoxShadowToPosition).join(", ")};
            }
            `;
        },
        [particles]
    );

    return (
        <StyledBackground
            duration={`${(scalingFactor * 60) / SPEED}s`}
            ref={backgroundRef}
            animationSteps={animationSteps}
            show={loaded}
        />
    );
}

const StyledBackground = styled.div<IStyledBackground>`
    position: absolute;
    z-index: -9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background: var(${CSSVariables.Background});
    background-color: var(${CSSVariables.BackgroundFallback});

    &:after {
        content: "";
        position: absolute;
        z-index: -9998;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        transition: opacity 0.3s linear;
        opacity: ${({ show }) => (show ? 1 : 0)};

        @keyframes myAnim {
            ${({ animationSteps }) => animationSteps};
        }

        animation-name: myAnim;
        animation-timing-function: linear;
        animation-duration: ${({ duration }) => `${duration}`};
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
`;

export default Background;
