import React from "react";
import styled from "styled-components";
import Background from "../Common/Background";
import "src/fonts.css";
import GlobalStyleComponent from "../Styles/GlobalStyle";
import CookieContextProvider from "src/hooks/useCookies";
import useStorageHook, { WebStorage } from "./useStorageHook";

interface ILayoutProps {
    children?: React.ReactNode;
}

function LayoutContent({ children }: ILayoutProps) {
    useStorageHook(WebStorage.Local);
    useStorageHook(WebStorage.Session);

    return (
        <Content>
            <Background />
            <PageContainer>
                <BodyContainer>{children}</BodyContainer>
            </PageContainer>
        </Content>
    );
}

const Layout: React.FC<ILayoutProps> = ({ children }) => (
    <CookieContextProvider>
        <GlobalStyleComponent>
            <LayoutContent>{children}</LayoutContent>
        </GlobalStyleComponent>
    </CookieContextProvider>
);

const Content = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
`;

const PageContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BodyContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export default Layout;
