import { css } from "styled-components";
import { CSSVariables } from "src/common/constants";

const Typography = css`
    body {
        font-family: "Inter", sans-serif;
        line-height: 2;
        font-weight: 400;
        color: var(${CSSVariables.TextColor});
    }

    h1 {
        line-height: 150%;
        font-weight: 600;
        font-size: var(${CSSVariables.FontSizeH1});
        color: var(${CSSVariables.TitleColor});
    }

    h2 {
        position: relative;
        line-height: 150%;
        font-weight: 500;
        font-size: var(${CSSVariables.FontSizeH2});
        color: var(${CSSVariables.TitleColor});
    }

    h3 {
        line-height: 150%;
        font-weight: 400;
        font-size: var(${CSSVariables.FontSizeH3});
        color: var(${CSSVariables.TitleColor});
    }

    h4 {
        line-height: 150%;
        font-weight: 300;
        font-size: var(${CSSVariables.FontSizeH4});
        color: var(${CSSVariables.TitleColor});
    }

    h5 {
        line-height: 150%;
        font-weight: 300;
        font-size: var(${CSSVariables.FontSizeH5});
        color: var(${CSSVariables.TitleColor});
    }

    h6 {
        line-height: 150%;
        font-weight: 300;
        font-size: var(${CSSVariables.FontSizeH6});
        color: var(${CSSVariables.TitleColor});
    }

    p,
    body {
        font-weight: 300;
        color: var(${CSSVariables.TextColor});
    }

    p,
    a,
    li {
        font-size: var(${CSSVariables.FontSizeLarge});
    }

    small {
        font-size: var(${CSSVariables.FontSizeSmall});
        font-weight: 300;
    }

    b,
    strong {
        font-weight: 600;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: var(${CSSVariables.LinkColor});
        position: relative;
        transition: color 0.2s, font-weight 0.1s;

        &:hover {
            color: #a639ff;
        }
    }
`;

export default Typography;
