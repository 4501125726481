import { ThemeKeys, CSSVariableKeys } from "./types";

export const AnchorSymbol = "#";
export const RemToVwCalculationMaxScreenWidth: number = 1920;
export const PixelsPerRem: number = 16;
export const ConvertFromPercentageMultiplier: number = 100;

type ICSS = Readonly<{
    PhoneMaxWidth: number;
    NavbarCollapse: number;
    ContentCollapse: number;
    MinimumSupportedWidth: number;
}>;

export const CSS: ICSS = {
    PhoneMaxWidth: 850,
    NavbarCollapse: 1022,
    ContentCollapse: 1100,
    MinimumSupportedWidth: 260
};
export const CSSNew = {
    mobileL: 500,
    tablet: 767,
    desktopS: 1023,
    desktop: 1439,
    desktopL: 1989
};

export const CSSVariables = Object.assign({}, ThemeKeys, CSSVariableKeys);
