import { useState, useCallback, useLayoutEffect, useContext } from "react";
import { CookieContext, CookieStorageKey } from "src/hooks/useCookies";
import { ThemeLocalStorageKey } from "../Styles/GlobalStyle";

const AllowedKeys = new Set<string>([
    CookieStorageKey,
    ThemeLocalStorageKey,
    "gatsby-i18next-language"
]);

export enum WebStorage {
    Local = "localStorage",
    Session = "sessionStorage"
}

function useStorageHook(storage: WebStorage) {
    const [storageFunction, setStorageFunction] = useState<{
        fn: (key: string, value: string) => void;
    } | null>(null);
    const { approval } = useContext(CookieContext);

    const overriddenSetItemFunction = useCallback(
        function (key: string, value: string, forceSet: boolean = false) {
            if (
                typeof window === "undefined" ||
                !AllowedKeys.has(key) ||
                storageFunction === null
            ) {
                return;
            }

            if (forceSet) {
                storageFunction.fn.bind(window[storage])(key, value);
            } else if (!forceSet && approval) {
                storageFunction.fn.bind(window[storage])(key, value);
            }
        },
        [storage, storageFunction, approval]
    );

    useLayoutEffect(
        function () {
            if (typeof window === "undefined") {
                return;
            }
            if (storageFunction === null) {
                setStorageFunction({ fn: window[storage].setItem });
            }

            window[storage].setItem = overriddenSetItemFunction;
        },
        [approval, overriddenSetItemFunction, storageFunction, storage]
    );
}

export default useStorageHook;
