import React from "react";
import Layout from "src/components/Layout/Layout";
import * as gatsby from "gatsby";

export const wrapRootElement: gatsby.GatsbyBrowser["wrapRootElement"] = ({ element }) => (
    <>{element}</>
);

export const wrapPageElement: gatsby.GatsbyBrowser["wrapPageElement"] = ({ element, props }) => (
    <Layout {...props}>{element}</Layout>
);

export const shouldUpdateScroll: gatsby.GatsbyBrowser["shouldUpdateScroll"] = ({ routerProps }) => {
    const {
        location: { hash }
    } = routerProps;

    const hashElement = hash !== "" ? document.getElementById(hash.slice(1)) : null;
    if (hashElement !== null) {
        setTimeout(() => hashElement.scrollIntoView(), 0);
        return false;
    }

    return true;
};
