exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-nextier-post-1-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/blog/nextier-post1.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-nextier-post-1-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-nextier-post-2-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/blog/nextier-post2.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-nextier-post-2-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-nextier-post-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/blog/nextier-post.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-nextier-post-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-content-clients-en-dnb-md": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/markdown-content/clients/en/dnb.md" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-markdown-content-clients-en-dnb-md" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */)
}

