import React, { useState, createContext, useCallback, useLayoutEffect } from "react";

export const CookieStorageKey = "cookiesApproved";

interface ICookieContext {
    approval: boolean;
    setApproval: (approval: boolean) => void;
    pruneCookies: () => void;
}

export const CookieContext = createContext<ICookieContext>({
    approval: false,
    setApproval: (_: boolean) => {
        /* */
    },
    pruneCookies: () => {
        /* */
    }
});

interface ICookieContextProvider {
    children: React.ReactNode;
}

const CookieContextProvider: React.FC<ICookieContextProvider> = ({ children }) => {
    const [approval, setApprovalState] = useState(false);

    const pruneCookies = useCallback(() => {
        sessionStorage.clear();
        localStorage.clear();
    }, []);

    const setApproval = useCallback(
        (approval: boolean) => {
            if (approval)
                (localStorage.setItem as (key: string, value: string, forceSet: boolean) => void)(
                    CookieStorageKey,
                    "true",
                    true
                );
            else pruneCookies(); // REMOVE ALL LOCALSTORAGE DATA IMMEDIATELY

            setApprovalState(approval);
        },
        [pruneCookies]
    );

    useLayoutEffect(() => {
        const storeCookies = localStorage.getItem(CookieStorageKey) === "true";
        setApproval(storeCookies);
    }, [setApproval]);

    return (
        <CookieContext.Provider value={{ approval, setApproval, pruneCookies }}>
            {children}
        </CookieContext.Provider>
    );
};

export default CookieContextProvider;
