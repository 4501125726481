import { CSS } from "../../common/constants";

const customMediaQueryMax = (maxWidth: number) => `@media screen and (max-width: ${maxWidth}px)`;
const customMediaQueryMin = (minWidth: number) => `@media screen and (min-width: ${minWidth}px)`;

const media = {
    customMax: customMediaQueryMax,
    customMin: customMediaQueryMin,
    minimumSupportedWidth: customMediaQueryMax(CSS.MinimumSupportedWidth),
    navbarCollapseMax: customMediaQueryMax(CSS.NavbarCollapse),
    navbarCollapseMin: customMediaQueryMin(CSS.NavbarCollapse + 1),
    contentCollapseMax: customMediaQueryMax(CSS.ContentCollapse),
    contentCollapseMin: customMediaQueryMin(CSS.ContentCollapse + 1),
    phoneMaxWidth: customMediaQueryMax(CSS.PhoneMaxWidth),
    xxl: customMediaQueryMax(1400),
    xl: customMediaQueryMax(1100),
    l: customMediaQueryMax(900),
    m: customMediaQueryMax(700),
    s: customMediaQueryMax(550),
    xs: customMediaQueryMax(400)
};

export default media;
