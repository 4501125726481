import "normalize.css";
import { remToVw } from "src/common/utils";
import { createGlobalStyle, css, CSSObject, FlattenSimpleInterpolation } from "styled-components";
import Typography from "./Typography";
import { CSSVariableKeys, Theme, ThemeKeys } from "src/common/types";
import React, { useContext, useCallback, useLayoutEffect, useState } from "react";
import { CookieContext } from "src/hooks/useCookies";
import Inputs from "./Inputs";
import media from "./MediaQueries";

type StrictCSSObjectValue = CSSObject | string | number;

type StrictCSSObject<T extends string> = {
    [key in T]: StrictCSSObjectValue;
};

const cssObjectToInterpolation = <T extends string>(
    cssObject: StrictCSSObject<T>
): FlattenSimpleInterpolation => css(cssObject as CSSObject);

export const ThemeCSSMap: ReadonlyMap<Theme, FlattenSimpleInterpolation> = new Map<
    Theme,
    FlattenSimpleInterpolation
>([
    [
        Theme.Dark,
        cssObjectToInterpolation<ThemeKeys>({
            [ThemeKeys.TitleColor]: "#0079CE",
            [ThemeKeys.ButtonBackground]: "hsl(205, 100%, 50%)",
            [ThemeKeys.ButtonBackgroundActive]: "hsl(205, 90%, 45%)",
            [ThemeKeys.ButtonBackgroundHover]: "hsl(205, 100%, 58%)",
            [ThemeKeys.DisplayTitleColor]:
                "radial-gradient(55.16% 426.25% at 27.6% 29.69%, #0095ff 0%, #004B80 100%), #0095ff",
            [ThemeKeys.TextColor]: "hsl(210, 80%, 95%)",
            [ThemeKeys.LinkColor]: "#0095ff",
            [ThemeKeys.LinkUnderlineColor]: "hsl(204.94117647058823, 100%, 50%)",
            [ThemeKeys.ContentBoxBackgroundColor]: "rgba(0, 0, 10, .9)",
            [ThemeKeys.ProminentBackgroundColor]: "#000000",
            [ThemeKeys.BackgroundFallback]: "hsl(240, 100%, 2.0%)",
            [ThemeKeys.Background]: `radial-gradient(
                circle at center 50vh,
                hsl(240, 100%, 0.0%) 0%,
                hsl(240, 100%, 4%) 33%,
                hsl(240, 100%, 2.0%) 66%,
                hsl(240, 100%, 5%) 85%
            )`,
            [ThemeKeys.ContentBoxPhoneBackgroundColor]: "rgba(0, 0, 10, .8)",
            [ThemeKeys.BurgerIcon]: "#0079CE",
            [ThemeKeys.ProjectsSeparatorColor]: "#858585",
            [ThemeKeys.SectionSeparatorColor]: "rgba(133, 133, 133, 0.25)",
            [ThemeKeys.ContrastTransparencyColor]: "rgba(255, 255, 255, 0.05)",
            [ThemeKeys.LogoGradientColor1]: "#0095ff",
            [ThemeKeys.LogoGradientColor2]: "#004B80",
            [ThemeKeys.JumpToActionArrowColor]: "#0095ff",
            [ThemeKeys.LightThemeIconColor]: "#0095ff",
            [ThemeKeys.DarkThemeIconColor]: "hsl(210, 80%, 95%)",
            [ThemeKeys.LanguageSelectionColorPrimary]: "#0079ce",
            [ThemeKeys.LanguageSelectionColorSecondary]: "#004371"
        })
    ],
    [
        Theme.Light,
        cssObjectToInterpolation<ThemeKeys>({
            [ThemeKeys.TitleColor]: "#005692",
            [ThemeKeys.ButtonBackground]: "hsl(230, 100%, 20%)",
            [ThemeKeys.ButtonBackgroundActive]: "hsl(230, 100%, 15%)",
            [ThemeKeys.ButtonBackgroundHover]: "hsl(230, 100%, 25%)",
            [ThemeKeys.DisplayTitleColor]:
                "radial-gradient(108.29% 922.1% at 27.6% 29.69%, hsl(210, 100%, 35.0%) 0%, #001166 100%), #001166;",
            [ThemeKeys.TextColor]: "#29333D",
            [ThemeKeys.LinkColor]: "#001166",
            [ThemeKeys.LinkUnderlineColor]: "#001166",
            [ThemeKeys.ContentBoxBackgroundColor]: "rgb(250, 252, 255, 0.9)",
            [ThemeKeys.ProminentBackgroundColor]: "#ffffff",
            [ThemeKeys.BackgroundFallback]: "hsl(210, 100%, 99%)",
            [ThemeKeys.Background]: `radial-gradient(
                circle at center 50vh,
                hsl(220, 100%, 100%) 0%,
               hsl(220, 100%, 97%) 33%,
               hsl(220, 100%, 98%) 66%,
               hsl(220, 100%, 96%) 85%
            )`,
            [ThemeKeys.ContentBoxPhoneBackgroundColor]: "rgba(250, 252, 255, 0.8)",
            [ThemeKeys.BurgerIcon]: "#0079CE",
            [ThemeKeys.ProjectsSeparatorColor]: "#858585",
            [ThemeKeys.SectionSeparatorColor]: "rgba(133, 133, 133, 0.25)",
            [ThemeKeys.ContrastTransparencyColor]: "rgba(0, 0, 180, 0.05)",
            [ThemeKeys.LogoGradientColor1]: "#0073E6",
            [ThemeKeys.LogoGradientColor2]: "#001166",
            [ThemeKeys.JumpToActionArrowColor]: "#3394D8",
            [ThemeKeys.LightThemeIconColor]: "hsl(210, 100%, 99%)",
            [ThemeKeys.DarkThemeIconColor]: "#001166",
            [ThemeKeys.LanguageSelectionColorPrimary]: "#005692",
            [ThemeKeys.LanguageSelectionColorSecondary]: "#54a5de"
        })
    ]
]);

export const getThemeFromProps: (props: { theme: Theme }) => FlattenSimpleInterpolation = ({
    theme
}) => ThemeCSSMap.get(theme) || css``;

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
    *, *::before, *::after {
        box-sizing: border-box;
        outline-color: #1fa9ff;
        font-size: inherit;
    }

    html {
        -moz-text-size-adjust: none;
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
    }

    :root {
        font-size: 16px;
        ${cssObjectToInterpolation<CSSVariableKeys>({
            [CSSVariableKeys.FontSizeH1]: `clamp(3rem, ${remToVw(6)}vw, 6rem)`,
            [CSSVariableKeys.FontSizeH2]: `clamp(2.25rem, ${remToVw(4.5)}vw, 4.5rem)`,
            [CSSVariableKeys.FontSizeH3]: `clamp(1.5rem, ${remToVw(3)}vw, 3rem)`,
            [CSSVariableKeys.FontSizeH4]: `clamp(1.35rem, ${remToVw(2)}vw, 2rem)`,
            [CSSVariableKeys.FontSizeH5]: `clamp(1.125rem, ${remToVw(1.625)}vw, 1.625rem)`,
            [CSSVariableKeys.FontSizeH6]: `clamp(0.875rem, ${remToVw(1.5)}vw, 1.5rem)`,
            [CSSVariableKeys.FontSize]: `clamp(0.625rem, ${remToVw(1.25)}vw, 1.25rem)`,
            [CSSVariableKeys.FontSizeSmall]: `clamp(0.5rem, ${remToVw(1)}vw, 1rem)`,
            [CSSVariableKeys.FontSizeLarge]: `clamp(1rem, ${remToVw(1.5)}vw, 1.5rem)`,
            [CSSVariableKeys.ContentBoxTitleSizeSmall]: `clamp(1.75rem, ${remToVw(4)}vw, 4rem)`,
            [CSSVariableKeys.ContentBoxTitleSizeLarge]: `clamp(2.5rem, ${remToVw(6)}vw, 6rem)`,
            [CSSVariableKeys.ContentBoxTitleWeight]: "500",
            [CSSVariableKeys.InfoFontSize]: `clamp(1.125rem, ${remToVw(1.5)}vw, 1.5rem)`,
            [CSSVariableKeys.InfoTextWeight]: "400",
            [CSSVariableKeys.NavbarFontSize]: `clamp(1.125rem, ${remToVw(1.5)}vw, 1.5rem)`,
            [CSSVariableKeys.NavbarFontWeight]: "500",
            [CSSVariableKeys.MobileNavFontSize]: "2rem",
            [CSSVariableKeys.MobileNavFontWeight]: "400",
            [CSSVariableKeys.ProjectsSubtitleFontSize]: `clamp(1.75rem, ${remToVw(
                2.625
            )}vw, 2.625rem)`,
            [CSSVariableKeys.ProjectsSubtitleFontWeight]: "300",
            [CSSVariableKeys.ProjectsWorkflowTitleFontWeight]: "300",
            [CSSVariableKeys.ProjectsWorkflowDescriptionFontSize]: `clamp(1.125rem, ${remToVw(
                1.5
            )}vw, 1.5rem)`,
            [CSSVariableKeys.ProjectsWorkflowDescriptionFontWeight]: "300",
            [CSSVariableKeys.NavbarDefaultHeight]: "9.27rem",
            [CSSVariableKeys.FooterFontSize]: `clamp(${3 / 2}rem, ${remToVw(3, 2880)}vw, 3rem)`
        })}

        ${media.customMax(350)} {

            ${cssObjectToInterpolation<CSSVariableKeys>({
                [CSSVariableKeys.FontSizeH1]: `clamp(${3 * 0.75}rem, ${remToVw(6 * 0.75)}vw, ${
                    6 * 0.75
                }rem)`,
                [CSSVariableKeys.FontSizeH2]: `clamp(${2.25 * 0.75}rem, ${remToVw(4.5 * 0.75)}vw, ${
                    4.5 * 0.75
                }rem)`,
                [CSSVariableKeys.FontSizeH3]: `clamp(${1.5 * 0.75}rem, ${remToVw(3 * 0.75)}vw, ${
                    3 * 0.75
                }rem)`,
                [CSSVariableKeys.FontSizeH4]: `clamp(${1.35 * 0.75}rem, ${remToVw(2 * 0.75)}vw, ${
                    2 * 0.75
                }rem)`,
                [CSSVariableKeys.FontSizeH5]: `clamp(${1.125 * 0.75}rem, ${remToVw(
                    1.625 * 0.75
                )}vw, ${1.625 * 0.75}rem)`,
                [CSSVariableKeys.FontSizeH6]: `clamp(${0.875 * 0.75}rem, ${remToVw(
                    1.5 * 0.75
                )}vw, ${1.5 * 0.75}rem)`,
                [CSSVariableKeys.FontSize]: `clamp(${0.625 * 0.75}rem, ${remToVw(1.25 * 0.75)}vw, ${
                    1.25 * 0.75
                }rem)`,
                [CSSVariableKeys.FontSizeSmall]: `clamp(${0.5 * 0.75}rem, ${remToVw(1 * 0.75)}vw, ${
                    1 * 0.75
                }rem)`,
                [CSSVariableKeys.FontSizeLarge]: `clamp(${1 * 0.75}rem, ${remToVw(1.5 * 0.75)}vw, ${
                    1.5 * 0.75
                }rem)`,
                [CSSVariableKeys.ContentBoxTitleSizeSmall]: `clamp(${1.75 * 0.75}rem, ${remToVw(
                    4 * 0.75
                )}vw, ${4 * 0.75}rem)`,
                [CSSVariableKeys.ContentBoxTitleSizeLarge]: `clamp(${2.5 * 0.75}rem, ${remToVw(
                    6 * 0.75
                )}vw, ${6 * 0.75}rem)`,
                [CSSVariableKeys.InfoFontSize]: `clamp(${1.125 * 0.75}rem, ${remToVw(
                    1.5 * 0.75
                )}vw, ${1.5 * 0.75}rem)`,
                [CSSVariableKeys.NavbarFontSize]: `clamp(${1.125 * 0.75}rem, ${remToVw(
                    1.5 * 0.75
                )}vw, ${1.5 * 0.75}rem)`,
                [CSSVariableKeys.MobileNavFontSize]: `${2 * 0.75}rem`,
                [CSSVariableKeys.ProjectsSubtitleFontSize]: `clamp(${1.75 * 0.75}rem, ${remToVw(
                    2.625 * 0.75
                )}vw, ${2.625 * 0.75}rem)`,
                [CSSVariableKeys.ProjectsWorkflowDescriptionFontSize]: `clamp(${
                    1.125 * 0.75
                }rem, ${remToVw(1.5 * 0.75)}vw, ${1.5 * 0.75}rem)`,
                [CSSVariableKeys.FooterFontSize]: `clamp(${(3 / 2) * 0.75}rem, ${remToVw(
                    3 * 0.75,
                    2880
                )}vw, ${3 * 0.75}rem)`
            } as StrictCSSObject<CSSVariableKeys>)}
        }

        ${({ theme }) =>
            theme === Theme.NotSelected
                ? css`
                      @media (prefers-color-scheme: dark) {
                          ${ThemeCSSMap.get(Theme.Dark)}
                      }
                      @media (prefers-color-scheme: light) {
                          ${ThemeCSSMap.get(Theme.Light)}
                      }
                  `
                : getThemeFromProps}


    }

    ${Typography}

    html {
        overflow-x: hidden;
    }

    body {
        background-color: var(${ThemeKeys.Background});
        overflow-y: auto;
    }

    ${Inputs}

    @keyframes appearAnimation {
        from {
            opacity: 0;
            transform: translateY(4rem);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }


    .appear-animation {
        opacity: 0;
        transform: translateY(3rem);
    }

    .appear-animation-visible {
        animation: appearAnimation .6s ease-in-out forwards;
    }
`;

interface IThemeContext {
    theme: Theme;
    switchTheme: (theme: Theme) => void;
}

export const ThemeContext = React.createContext<IThemeContext>({
    theme: Theme.Light,
    switchTheme: (_: Theme) => {
        /* */
    }
});

const valueToThemeMap: Record<string, Theme> = {
    dark: Theme.Dark,
    light: Theme.Light
};

export const ThemeLocalStorageKey = "theme";
const defaultTheme: Theme = Theme.NotSelected;

const parseThemeFromLocalStorage = (): Theme => {
    const valueInLocalStorage = localStorage.getItem(ThemeLocalStorageKey);
    return valueInLocalStorage !== null
        ? valueToThemeMap[valueInLocalStorage] || defaultTheme
        : defaultTheme;
};

const useCurrentTheme: () => [Theme, React.Dispatch<React.SetStateAction<Theme>>] = () => {
    const [currentTheme, setCurrentTheme] = useState<Theme>(defaultTheme);

    useLayoutEffect(() => {
        const themeFromLocalStorage = parseThemeFromLocalStorage();

        const themeFromDeviceSettings =
            window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)")
                ? Theme.Dark
                : Theme.Light || Theme.NotSelected;
        setCurrentTheme(
            themeFromLocalStorage === Theme.NotSelected
                ? themeFromDeviceSettings
                : themeFromLocalStorage
        );
    }, []);

    return [currentTheme, setCurrentTheme];
};

const GlobalStyleFC: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { approval } = useContext(CookieContext);

    const [currentTheme, setCurrentTheme] = useCurrentTheme();

    const switchTheme = useCallback(
        (theme: Theme) => {
            if (approval) localStorage.setItem(ThemeLocalStorageKey, theme);

            setCurrentTheme(theme);
        },
        [approval, setCurrentTheme]
    );

    return (
        <ThemeContext.Provider value={{ theme: currentTheme, switchTheme }}>
            <GlobalStyle theme={currentTheme} />
            {children}
        </ThemeContext.Provider>
    );
};

export default GlobalStyleFC;
