import { useLayoutEffect, useState } from "react";

export interface ISize {
    width: number;
    height: number;
}

const useElementSize = <T extends HTMLElement>(
    elementRef: React.MutableRefObject<T | null>
): ISize => {
    const [elementSize, setElementSize] = useState<ISize>({
        width: 0,
        height: 0
    });

    useLayoutEffect(() => {
        if (elementRef.current !== null) {
            const { width, height } = elementRef.current.getBoundingClientRect();
            setElementSize({ width, height });
        }
    }, [elementRef]);

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(([elem]) => {
            const { width, height } = elem?.target.getBoundingClientRect() || {
                width: 0,
                height: 0
            };
            setElementSize({ width, height });
        });
        if (elementRef.current !== null) resizeObserver.observe(elementRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [elementRef]);

    return elementSize;
};

export default useElementSize;
