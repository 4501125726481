export enum ThemeKeys {
    ButtonBackground = "--button-background",
    ButtonBackgroundHover = "--button-background-hover",
    ButtonBackgroundActive = "--button-background-active",
    TitleColor = "--title-color",
    DisplayTitleColor = "--display-title-color",
    TextColor = "--text-color",
    LinkColor = "--link-color",
    LinkUnderlineColor = "--link-underline-color",
    ContentBoxBackgroundColor = "--content-box-background-color",
    ProminentBackgroundColor = "--prominent-background-color",
    Background = "--background",
    ContentBoxPhoneBackgroundColor = "--content-box-phone-background-color",
    BackgroundFallback = "--background-fallback",
    BurgerIcon = "--burger-icon",
    ProjectsSeparatorColor = "--projects-separator-color",
    SectionSeparatorColor = "--section-separator-color",
    ContrastTransparencyColor = "--contrast-transparency-color",
    LogoGradientColor1 = "--logo-gradient-color-1",
    LogoGradientColor2 = "--logo-gradient-color-2",
    JumpToActionArrowColor = "--jump-to-action-arrow-color",
    LightThemeIconColor = "--light-theme-icon-color",
    DarkThemeIconColor = "--dark-theme-icon-color",
    LanguageSelectionColorPrimary = "--language-selection-color-primary",
    LanguageSelectionColorSecondary = "--language-selection-color-secondary"
}

export enum CSSVariableKeys {
    FontSizeH1 = "--font-size-h1",
    FontSizeH2 = "--font-size-h2",
    FontSizeH3 = "--font-size-h3",
    FontSizeH4 = "--font-size-h4",
    FontSizeH5 = "--font-size-h5",
    FontSizeH6 = "--font-size-h6",
    FontSize = "--font-size",
    FontSizeSmall = "--font-size-small",
    FontSizeLarge = "--font-size-large",
    ContentBoxTitleSizeSmall = "--content-box-title-size-small",
    ContentBoxTitleSizeLarge = "--content-box-title-size-large",
    ContentBoxTitleWeight = "--content-box-title-weight",
    InfoFontSize = "--info-font-size",
    InfoTextWeight = "--info-text-weight",
    NavbarFontSize = "--navbar-font-size",
    NavbarFontWeight = "--navbar-font-weight",
    MobileNavFontSize = "--mobile-nav-font-size",
    MobileNavFontWeight = "--mobile-nav-font-weight",
    ProjectsSubtitleFontSize = "--projects-subtitle-font-size",
    ProjectsSubtitleFontWeight = "--projects-subtitle-font-weight",
    ProjectsWorkflowTitleFontWeight = "--projects-workflow-title-font-weight",
    ProjectsWorkflowDescriptionFontSize = "--projects-workflow-description-font-size",
    ProjectsWorkflowDescriptionFontWeight = "--projects-workflow-description-font-weight",
    NavbarDefaultHeight = "--navbar-default-height",
    FooterFontSize = "--footer-font-size"
}

export enum Theme {
    Dark = "dark",
    Light = "light",
    NotSelected = "notSelected"
}

export type Range = Readonly<[number, number]>;
