import { useLayoutEffect, useState } from "react";

/**
 * Why disable these eslint rules?
 * It's fine to disable these rules in this case,
 * since useDebounce should have access to any number of parameters
 * which can be any type.
 * In either case, this function in the end will use only strictly typed
 * variables, it will never return the "any" type.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
const useDebounce = <T1,>(
    defaultValue: T1,
    getDelayMsFn: (currentValue: T1, ...valueChangeFactors: any[]) => number,
    setToValueFn: (...valueChangeFactors: any[]) => T1,
    ...valueChangeFactors: any[]
) => {
    const [state, setState] = useState<T1>(defaultValue);

    useLayoutEffect(() => {
        const openTimeout = setTimeout(() => {
            setState(setToValueFn(...valueChangeFactors));
        }, getDelayMsFn(state, ...valueChangeFactors));
        return () => {
            clearTimeout(openTimeout);
        };
    }, [getDelayMsFn, setToValueFn, state, valueChangeFactors]);

    return state;
};
/* eslint-enable */

export default useDebounce;
