import * as Constants from "./constants";
import { Range } from "./types";

export const getAnchorId = (text: string): string =>
    encodeURIComponent(
        text.replaceAll(" ", "-").replaceAll("ä", "a").replaceAll("ö", "ö").toLowerCase()
    );

export const convertToHref = (text: string): string =>
    `/${Constants.AnchorSymbol}${getAnchorId(text)}`;

export const remToVw = (
    remValue: number,
    maxWidth: number = Constants.RemToVwCalculationMaxScreenWidth,
    pixelsPerRem: number = Constants.PixelsPerRem
): number => ((remValue * pixelsPerRem) / maxWidth) * Constants.ConvertFromPercentageMultiplier;

export const isValueLessOrEqual = (value: number, than: number): boolean => value <= than;

export const windowExists = () => typeof window !== "undefined";

export const createObject = <T>(obj: T): Readonly<T> => obj;

export const convertRange = (value: number, [min1, max1]: Range, [min2, max2]: Range): number =>
    ((value - min1) * (max2 - min2)) / (max1 - min1) + min2;

export const createArray = <T>(length: number, mapFn: () => T): Readonly<T[]> =>
    new Array(length).fill(1).map(mapFn);

export const getRandom = (min: number, max: number): number => Math.random() * (max - min) + min;

export const clampedSize = (
    remSize: number,
    min: number | null = null,
    max: number | null = null,
    scalesTo: number = Constants.RemToVwCalculationMaxScreenWidth
): string =>
    `clamp(${min === null ? remSize / 2 : min}rem, ${remToVw(remSize, scalesTo)}vw, ${
        max === null ? remSize : max
    }rem)`;
